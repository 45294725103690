import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import { Search, TextSnippet, List } from '@mui/icons-material';

import { useSelector } from 'react-redux';
import { printPage } from './PrintComponents';

import PrintContext from '../hooks/printContext';
import Hexagone from './Hexagone';

const HexaIcon = () => (
  <>
    <Hexagone
      bgProps={{ fill: 'white' }}
      pathProps={{ stroke: 'currentcolor', strokeWidth: 2.2, inset: 0 }}
    />
    <Box
      sx={{
        position: 'absolute',
        left: '40%',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      °C
    </Box>
  </>
);

const SpeedDialWithTooltip = ({
  code,
  nom,
  handleTableOfContentDialogOpen,
  handleSearchDialogOpen,
  setOpenOutlookDialog,
}) => {
  const [open, setOpen] = React.useState(false);
  const outlook = useSelector(state => state.outlook.value);

  const handleOpen = (event, reason) => { setOpen(true); console.log(reason); };
  const handleClose = (event, reason) => { setOpen(false); console.log(reason); };

  const { openPrintDialog } = React.useContext(PrintContext);
  const actions = [
    {
      icon: <Search />,
      tooltipTitle: 'Communes',
      onClick: () => { setOpen(false); handleSearchDialogOpen?.(); },
    },
    {
      icon: <HexaIcon />,
      tooltipTitle: 'Horizons',
      onClick: () => { setOpen(false); setOpenOutlookDialog?.(true); },
    },
    {
      icon: <List />,
      tooltipTitle: 'Indicateurs',
      onClick: () => { setOpen(false); handleTableOfContentDialogOpen?.(); },
    },
    {
      icon: <TextSnippet />,
      tooltipTitle: 'Téléchargement',
      onClick: () => {
        setOpen(false); printPage?.({ code, name: nom, outlook, showPrint: openPrintDialog });
      },
    },
  ];

  return (
    <Box
      sx={{
        displayPrint: 'none',
        position: 'fixed',
        top: 60,
        right: 15,
        zIndex: 2,
      }}
      className="speedDialWithTooltip"
    >
      <Backdrop open={open} sx={{ pointerEvents: 'none' }} />
      <SpeedDial
        ariaLabel="Navigation mobile"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onClick={handleOpen}
        open={open}
        direction="down"
        FabProps={{ size: 'medium' }}
      >
        {actions.map(({ name, ...rest }) => (
          <SpeedDialAction key={rest.tooltipTitle} tooltipOpen {...rest} />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default SpeedDialWithTooltip;
