import React from 'react';

import { ListItem, List, ListItemText, ListItemButton, Checkbox } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import useTokenReplacement from '../hooks/useTokenReplacement';

const selectItem = (itemKey, itemsStates, toggleItem, scrollTo) => {
  const justBeenSelected = !itemsStates[itemKey];

  // unslect item => only unslect
  toggleItem(itemKey);

  // select item => do scroll to it as well
  if (justBeenSelected) {
    setTimeout(() => scrollTo(itemKey), 10);
  }
};

const clickOnItem = (itemKey, itemsStates, scrollTo, toggleItem, isIndicator) => {
  const isSelected = itemsStates[itemKey];

  // if an indicator is not selected
  // click on this item trigger its selection
  if (isIndicator && !isSelected) { toggleItem(itemKey); }
};

const indicatorCheckbox = (
  itemKey, isIndicator, isFixedIndicator, indicatoreColor,
  itemsStates, toggleItem, scrollTo,
) => {
  if (!isIndicator) {
    return null;
  }

  const checkboxStyle = { sx: { color: indicatoreColor, '&.Mui-checked': { color: indicatoreColor } } };

  return !isFixedIndicator ? (
    <Checkbox
      edge="start"
      checked={itemsStates[itemKey]}
      disabled={isFixedIndicator}
      onChange={() => selectItem(itemKey, itemsStates, toggleItem, scrollTo)}
      tabIndex={-1}
      disableRipple
      inputProps={{ 'aria-labelledby': itemKey }}
      {...checkboxStyle}
    />
  ) : (
    <Checkbox
      defaultChecked
      disabled
      icon={<CheckBoxOutlineBlankIcon />}
      checkedIcon={<SquareIcon />}
      {...checkboxStyle}
    />
  );
};

const NestedList = ({
  groups = [],
  itemsStatus = {},
  itemsStates = {},
  toggleItem,
  scrollTo,
  entityType,
}) => {
  const replaceTokens = useTokenReplacement({ entityType });

  return (
    <List>
      {groups.map(({ navTitle: title, key, items }) => {
        const hasItems = items.length > 0;
        return (
          <React.Fragment key={`nav-${key}`}>
            <ListItem button onClick={() => scrollTo(key)} sx={{ pt: 1 }}>
              <ListItemText primary={replaceTokens(title)} />
            </ListItem>

            {hasItems && (
              <>
                {items.map(({ navTitle: itemTitle, key: itemKey, isIndicator }) => {
                  const isFixedIndicator = itemsStatus[itemKey];
                  let indicatoreColor = 'grey'; // for text which is not indicator
                  if (isFixedIndicator) { indicatoreColor = '#02568d'; } // fixed indicator
                  if (!isFixedIndicator && isIndicator) { indicatoreColor = '#009f98'; } // optionnal indicator

                  const checkbox = indicatorCheckbox(
                    itemKey, isIndicator, isFixedIndicator, indicatoreColor,
                    itemsStates, toggleItem, scrollTo,
                  );

                  return (
                    <ListItem
                      key={`list-item-${itemKey}`}
                      disablePadding
                      sx={{ paddingLeft: 2 }}
                      secondaryAction={checkbox}
                    >
                      <ListItemButton
                        component="a"
                        href={`#${itemKey}`}
                        key={`list-item-button-${itemKey}`}
                        onClick={() =>
                          clickOnItem(itemKey, itemsStates, scrollTo, toggleItem, isIndicator)}
                        dense
                      >
                        <ListItemText
                          id={`title-${itemKey}`}
                          primary={itemTitle}
                          primaryTypographyProps={{
                            style: { color: indicatoreColor },
                            fontWeight: itemsStates[itemKey] ? 'bold' : 'normal',
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </>
            )}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default NestedList;
