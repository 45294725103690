/* eslint-disable max-len */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { Typography, Box, Divider, Drawer, useMediaQuery, Dialog, GlobalStyles } from '@mui/material';
import { Helmet } from 'react-helmet';

import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';

import Layout from '../components/Layout';
import ContentPageNavigation from '../components/ContentPageNavigation';
import SearchItem from '../components/Search';
import { FirstPage, LastPage } from '../components/PrintComponents';
import SpeedDialWithTooltip from '../components/SpeedDialWithTooltip';
import OutlookSwitcher from '../components/OutlookSwitcher';

import useSearchEntities from '../hooks/useSearchEntities';
import useInteractiveIframe from '../hooks/useInteractiveIframe';

import { homePage, contentHierarchy as unfilteredPageContentHierarchy } from '../settings';
import PrintButton from '../components/PrintButton';
import EntityPageContent from '../components/EntityPageContent';

const inFrame = (typeof window !== 'undefined') && (window.parent !== window);

const EntityPage = ({
  data: {
    entity = {},
  },

  pageContext: {
    indicatorsWithStatus,
  },

  location,
}) => {
  const pageContentHierarchy = unfilteredPageContentHierarchy.map(group => ({
    ...group,
    items: group?.items.filter(
      ({ key, isIndicator, disabled }) => {
        if (disabled) { return false; }

        const hasData = key in indicatorsWithStatus;
        const notAnIndicator = !isIndicator;

        return hasData || notAnIndicator;
      },
    ),
  }));

  const { nom, code, type: entityType } = entity;
  const searchEntities = useSearchEntities();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.only('md'));
  let screenType = 'largeScreen';
  if (isMediumScreen) { screenType = 'mediumScreen'; }
  if (isSmallScreen) { screenType = 'smallScreen'; }

  const scrollToKey = key => scrollTo(`#${key}`);
  const [itemsStates, setItemsStates] = React.useState(indicatorsWithStatus);

  const {
    parentScroll,
    parentHeight,
    maskedHeight,
  } = useInteractiveIframe({ key: itemsStates, location });

  const toggleItem = item => {
    setItemsStates(prevState => ({ ...prevState, [item]: !prevState[item] }));
  };

  const entityLabel = code
    ? `${nom} (${code})`
    : nom;

  // table of content dialog
  const [openTableOfContentDialog, setTableOfContentOpenDialog] = React.useState(false);
  const handleTableOfContentDialogOpen = () => {
    setTableOfContentOpenDialog(true);
  };
  const handleTableOfContentDialogClose = () => {
    setTableOfContentOpenDialog(false);
  };

  // search dialog
  const [openSearchDialog, setSearchOpenDialog] = React.useState(false);
  const handleSearchDialogOpen = () => {
    setSearchOpenDialog(true);
  };
  const handleSearchDialogClose = () => {
    setSearchOpenDialog(false);
  };

  // outlook dialog
  const [openOutlookDialog, setOpenOutlookDialog] = React.useState(false);

  const searchComponent = (
    <SearchItem
      options={searchEntities}
      getLabel={option => option?.label}
      getGroup={option => option?.type}
      onChange={async (event, value) => {
        if (value?.identifiant) {
          navigate(`/entity/${value?.identifiant}#caracteristiques`);
          setTimeout(() => navigate('#caracteristiques'), 500); // Sometimes, navigate doesn't scroll to the anchor
        }
      }}
      searchLabel={homePage.searchText}
    />
  );

  // searchComponent in navComponent is only used for the mobile version
  const navComponent = (
    <ContentPageNavigation
      pageContentHierarchy={pageContentHierarchy}
      itemsStatus={indicatorsWithStatus}
      itemsStates={itemsStates}
      toggleItem={toggleItem}
      scrollTo={scrollToKey}
      entityType={entityType}
      handleTableOfContentDialogClose={handleTableOfContentDialogClose}
      openTableOfContentDialog={openTableOfContentDialog}
      handleSearchDialogClose={handleSearchDialogClose}
      openSearchDialog={openSearchDialog}
    >
      {searchComponent}
    </ContentPageNavigation>
  );

  return (
    <Layout
      title={entityLabel}
      hasHeader
      code={code}
      nom={nom}
      handleTableOfContentDialogOpen={handleTableOfContentDialogOpen}
      handleSearchDialogOpen={handleSearchDialogOpen}
    >
      {inFrame && (
        <Helmet>
          <style>{`
            .sidebar-spacer { height: ${(parentScroll || 0) - 50}px; }
            .sidebar-content { height: ${parentHeight - (maskedHeight)}px; }

            .speedDialWithTooltip { top: ${(parentScroll || 0) + 60}px; }

            .MuiModal-root.MuiDialog-root { top: ${parentScroll || 0}px; }
            .MuiModal-root.MuiDialog-root .MuiDialog-container {
              height: ${parentHeight - (maskedHeight)}px;
            }
          `}
          </style>
        </Helmet>
      )}

      <Box
        sx={{
          display: 'flex',
          mt: '50px',
          mb: '56px',
          '@media print': { mb: 0 },
        }}
      >
        <CssBaseline />
        <GlobalStyles
          styles={{
            html: { minHeight: '100%' },
            '*': { scrollBehavior: 'smooth' },
          }}
        />

        {isSmallScreen && (
          <SpeedDialWithTooltip
            code={code}
            nom={nom}
            handleTableOfContentDialogOpen={handleTableOfContentDialogOpen}
            handleSearchDialogOpen={handleSearchDialogOpen}
            setOpenOutlookDialog={setOpenOutlookDialog}
          />
        )}

        <Dialog
          disableRestoreFocus
          fullWidth
          onClose={() => setOpenOutlookDialog(false)}
          open={openOutlookDialog}
        >
          <OutlookSwitcher
            direction="column"
            onChange={() => {
              setTimeout(() => setOpenOutlookDialog(false), 300);
            }}
            sx={{
              width: 120,
              mx: 'auto',
              my: 5,
              fontSize: '1.5em',
            }}
            spacing={2}
          />
        </Dialog>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: isSmallScreen ? 2 : 3,
            '@media print': { pb: 0 },
          }}
        >
          <FirstPage nom={nom} />
          <EntityPageContent
            entity={entity}
            contentHierarchy={pageContentHierarchy}
            itemsState={itemsStates}
            screenType={screenType}
          />
          <LastPage />
        </Box>

        {isSmallScreen && navComponent}
        {!isSmallScreen && (
          <Drawer
            className="hidden-print"
            sx={{
              p: 1,
              width: 350,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 350,
                mt: '50px',
                height: 'calc(100% - 50px)',
                boxSizing: 'border-box',
              },

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
            variant="permanent"
            anchor="right"
          >
            <Box
              sx={{
                flexShrink: 1,
                height: '1px',
                overflow: 'hidden',
              }}
              className="sidebar-spacer"
            />

            <Box
              sx={{
                px: 3,
                overflow: 'auto',
                flexShrink: 0,
              }}
              className="sidebar-content"
            >
              <Box sx={{ position: 'fixed', width: 300, backgroundColor: 'white', zIndex: 3, paddingTop: 3 }}>
                {searchComponent}
                <Typography variant="h6" align="center" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
                  {entityLabel}
                </Typography>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography variant="body2" align="center" sx={{ mt: 1.5 }}>
                  Sélectionnez votre horizon
                </Typography>
                <OutlookSwitcher sx={{ width: 240, mx: 'auto', mt: 1.5 }} />
                <PrintButton code={code} nom={nom} />
                <Divider light variant="middle" sx={{ mt: 2, mb: 2 }} />
              </Box>

              <Box mt={38}>{navComponent}</Box>
            </Box>
          </Drawer>
        )}
      </Box>
    </Layout>
  );
};

export default EntityPage;

export const pageQuery = graphql`
  query MyQuery($identifiant_insee: String) {
    entity: entitiesJson(identifiant_insee: { eq: $identifiant_insee }) {
      nom
      code: code_recherche
      epci: nom_epci_parent
      epciId: identifiant_epci_parent
      population
      departement: nom_departement
      risques
      altMin: alt_minimum
      altMax: alt_maximum
      icu,
      massifMax: tranche_alt_max
      massifMin: tranche_alt_min
      massif: massif_rattachement
      port: port_rattachement
      entityType: type
      type
      indicateurs { id, data { label, max, mean, min, ref, type_ind }
      }
    }
  }
`;
